import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router'
import { browserHistory } from 'browserHistory'
import upperFirst from 'lodash/upperFirst'
import moment from 'moment'

import { hasAccessToPath, hasRole } from '../../../core/services/auth'
import { MetadataContainerTypes } from '../../../core/constants'
import Const, { AllRegions } from '../../../core/constants'

// import InlineAction from '../../../components/actions'

import Preview from '../../../components/assets/preview'
import Label from '../../../components/ui/label'
// import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'
import ContentItem, { Content } from '../../../components/list/contentItem'

import MetadataModule from '../module'

import Actions from '../actions'
import { programHasAgeRating } from './utils'

import appConfig from 'config'

import '../../../components/listItems/standard.css'

const ENTITY = "program";
const USE_NEW_LAYOUT = appConfig.features.metadataNewActionsLayout;
const SHOW_SUBTITLE_EDITOR = appConfig.features.metadataSubtitleEditor;
const AGE_RATING_TITLE = USE_NEW_LAYOUT ? "Age" : "Age rating";
const TRANSLATION_LANGUAGE_ORDER = [5, 1, 2, 3, 4];

@withRouter
export default class ProgramContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		reference: PropTypes.string,
		provider: PropTypes.object,
		approval: PropTypes.object,
		seasonApproval: PropTypes.object,
		seriesApproval: PropTypes.object,
		imageApproval: PropTypes.object,
		versions: PropTypes.array,
		defaultGenres: PropTypes.array,
		countries: PropTypes.array,
		duration: PropTypes.number,
		productionYear: PropTypes.string,

		displayName: PropTypes.string,

		parentId: PropTypes.number,
		numberOfEpisodes: PropTypes.number,

		itemTemplateModification: PropTypes.object,
		isMasterTemplate: PropTypes.bool,
		masterId: PropTypes.number,
		dataSource: PropTypes.string,

		className: PropTypes.string,
		isSeasonList: PropTypes.bool,
		isTranslationList: PropTypes.bool,

		location: PropTypes.object, // From @withRouter
	}

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);

		this.el = null;
	}

	componentDidMount() {
		scrollToElement(this.props, this.el); // Scroll to anchored list item
	}

	componentDidUpdate() {
		scrollToElement(this.props, this.el); // Scroll to anchored list item
	}

	handleClick(id, pathname, wideModal, typeId, query, typeName) {
		const { location, isSeasonList, isTranslationList } = this.props;
		const targetStore = getTargetStore(isSeasonList, isTranslationList);
		Actions.edit({
			id,
			pathname,
			query,
			returnTo: `${location.pathname}${location.search}`,
			targetStore,
			wideModal,
			typeId,
			typeName,
		});
	}

	render() {
		const {
			id,
			guid,
			displayName,
			// master = {},
			isMasterTemplate = false,
			// rights,
			dataSource,
			className = "",
			renderMenuWhenNoActions = false,

			location
		} = this.props;

		// const masterSlaveStatus = /*master.id*/ id % 9 == 0 ? <small>slave</small> : null; // %9 for some randomness


		// const premiere = rights && rights.length && rights[0];
		// const hoverInfo = premiere ? `${premiere.rightType}: ${Moment(premiere.start).format("d MMM YYYY HH:mm")} Program id: ${id}` : "";
		// data-hoverinfo={hoverInfo}

		// Highlight anchored list item
		const { hash } = this.props.location;
		const selectedClass = hash && parseInt(hash.replace("#p", "")) === id ? " sel" : "";

		// const title = type === "Season" && !seasonNumber && displayName !== `${name} S0`
		// 	? `${displayName} (${name})`
		// 	: displayName;

		const title = displayName;

		return (
			<ContentItem
				extraClasses={`${className}${selectedClass}`}
				itemRef={ref => this.el = ref}
				data={this.props}
				actionData={this.props}
				renderMenuWhenNoActions={renderMenuWhenNoActions}
			>
				<Preview programId={guid} />
				<Content className="info">
					<h1>{title} {getProvider(this.props)} {isMasterTemplate && <Label>Metadata template</Label>}</h1>
					<p>{getMetadata(this.props)} {getLink(this.props)} {getDataSourceInfo(dataSource)} {getFamilyActions(this.props)}</p>
					{/*masterSlaveStatus*/}
				</Content>
				{appConfig.features.metadataRightsInList && getRightPeriods(this.props)}
				{getActionContent(this.props, this.handleClick, location)}
			</ContentItem>
		);
	}
}

// HELPERS
function scrollToElement({ location, id }, el) {
	const { hash, ...locationWithoutHash } = location;
	if (hash && parseInt(hash.replace("#p", "")) === id) {
		window.setTimeout(() => {
			if (el) {
				el.scrollIntoView();
			}

			// HACK: We can't remove the hash straight away since the
			// scrolling will stop due to react re-rendering the list
			// NOTE: This will also remove the list item sel highlight
			window.setTimeout(() => {
				// Check if current pathname is same as when the timeout was set.
				// Otherwise if a user quickly opens an editor, they will be sent back to the list and that's frustrating :(
				const newPathname = appConfig.app.basePath
					? window.location.pathname.replace(`${appConfig.app.basePath}/`, "")
					: window.location.pathname;
				if (newPathname === location.pathname) {
					browserHistory.replace(locationWithoutHash);
				}
			}, 2500);

		}, 0);
	}
}

function handleScrollToItem(masterId, location, isSeasonList, e) {
	// If this is a season list we scroll to the item, otherwise we have to navigate to the season list.
	if (isSeasonList) {
		e.preventDefault();
		e.stopPropagation();
		// eslint-disable-next-line no-unused-vars
		const { action, key, ...route } = location;
		route.hash = `#p${masterId}`;
		browserHistory.replace(route);
	}
}

function getActionContent(
	item,
	handleClick,
	location,
) {
	const {
		id,
		parentId,
		guid,
		reference,
		displayName,
		approval,
		tagsApproval,
		imageApproval,
		videoApproval,
		versions: originalVersions,
		type,
		masterId,
		itemTemplateModification = false,
		provider,
		catalogue = "all",
		isSeasonList,
		isTranslationList,
		translationLanguage,
		onVideoClick = () => {},
		onSubtitleClick,
	} = item;
	
	// TODO!!!: We should possibly just render the versions which the API provides and remove this
	// since programs for the same customer need to have different language editors. (C More also
	// has TV4 programs which only are available on the Swedish C More service)
	const catalogueVersions = getCatalogueVersions(catalogue, isTranslationList && translationLanguage);
	const versions = (originalVersions ?? [])
	.filter(v => !(isTranslationList && translationLanguage) || catalogueVersions.includes(v.versionId))
	.sort((vA, vB) =>  TRANSLATION_LANGUAGE_ORDER.indexOf(vA.versionId) - TRANSLATION_LANGUAGE_ORDER.indexOf(vB.versionId));
	
	// Don't render any actions if this is an episode and it will use or will stop using a metadata template
	if (itemTemplateModification) {
		return (
			<Content className="c6-content-actions c6-content-template-actions">
				<p className="right">{itemTemplateModification.event === "removed" ? "Episode will stop using the metadata template in a moment." : "Episode will use the metadata template in a moment."}</p>
			</Content>
		);
	}

	// Don't render any actions if this is an episode and it uses a metadata template
	if (masterId) {

		// If the user has recently added a new master template, use the correct ID
		const masterTemplateId = itemTemplateModification && itemTemplateModification.event === "added"
			? itemTemplateModification.modifiedMasterId
			: masterId;

		// Set the correct approval state
		const everythingApproved = approval && approval.status && approval.status.toLowerCase() === "approved"
			&& imageApproval && imageApproval.status && imageApproval.status.toLowerCase() === "approved"
			&& versions.every(v => v.approval && v.approval.status && v.approval.status.toLowerCase() === "approved");
		const templateClassName = everythingApproved ? "right c6-text-approved icon-check" : "right c6-text-unapproved icon-close";

		return (
			<Content size="" className="c6-content-actions c6-content-template-actions">
				<p className={templateClassName}>Episode uses <Link className="c6-link" to={getLinkUrl(id, parentId, type, catalogue, masterTemplateId)} onClick={handleScrollToItem.bind(this, masterTemplateId, location, isSeasonList)}>metadata template</Link>.</p>
			</Content>
		);
	}

	// Otherwise let's render some real actions
	const hasTags = (type === "Series" || type === "Single" || type === "Family") && catalogue !== "mtv"; // TODO: Replace customer !== "mtv" with appConfig?
	const hasVideo = (type === "Episode" || type === "Single") && (catalogue === "britbox" || catalogue === "hayu" || catalogue === "sfkids"); // TODO: Replace customer === "britbox" with appConfig?
	const hasAgeRating = programHasAgeRating(item, catalogue);
	const finnishVersion = versions && versions.find(v => v.versionId === 4);
	const finnishRatingApproval = finnishVersion && finnishVersion.finnishRatingApproval;

	const tagsAccess = { module: "metadata", app: "tags", access: "reader" };
	const imagesAccess = { module: "metadata", app: "images", access: "reader" };
	const hasAgeRatingRole = hasRole("metadata.finnish") || hasRole("metadata.finnish-age-rating");

	return (
		<Content size="" className={`c6-content-actions ${USE_NEW_LAYOUT ? "new-layout" : ""}`}>
			{getAction({
				id,
				title: "Generic",
				approval,
				onClick: handleClick,
				catalogue,
			})}

			{getAction({
				id,
				title: AGE_RATING_TITLE,
				approval: finnishRatingApproval,
				onClick: handleClick,
				name: displayName,
				ignore: !hasAgeRating,
				disabled: !hasAgeRatingRole,
			})}

			{getAction({
				hasAccessToPath,
				access: tagsAccess,
				id,
				title: "Tags",
				approval: tagsApproval,
				onClick: handleClick,
				name: displayName,
				reference,
				guid,
				provider,
				catalogue,
				ignore: !hasTags,
			})}

			{USE_NEW_LAYOUT && getAction({
				hasAccessToPath,
				access: imagesAccess,
				id,
				title: "Video",
				approval: videoApproval,
				onClick: () => onVideoClick(item),
				reference,
				guid,
				provider,
				type,
				catalogue,
				ignore: !hasVideo,
			})}

			{getAction({
				hasAccessToPath,
				access: imagesAccess,
				id,
				title: "Media",
				approval: imageApproval,
				onClick: handleClick,
				reference,
				guid,
				provider,
				type,
				catalogue,
			})}

			{versions && versions.map((v) => getAction({
				id,
				title: USE_NEW_LAYOUT
					? AllRegions.find(r => r.id === v.versionId).languageDisplayNameShort.toUpperCase()
					: AllRegions.find(r => r.id === v.versionId).languageDisplayName,
				approval: v.approval,
				onClick: handleClick,
				versionId: v.versionId,
				catalogue,
				isTranslationList,
				ignore: appConfig.features.metadataIgnoreVersionsWithoutRights
					? shouldIgnoreVersion(item, v.versionId)
					: false,
			}))}

			{SHOW_SUBTITLE_EDITOR && onSubtitleClick && getAction({
				hasAccessToPath,
				access: imagesAccess,
				id,
				title: "Subs",
				approval: videoApproval,
				onClick: () => onSubtitleClick(item),
				reference,
				guid,
				provider,
				type,
				catalogue,
				ignore: !hasVideo,
				newRow: true
			})}
		</Content>
	);
}

function getLink({
	type,
	isSeasonList = false,
	isTranslationList = false,
	translationLanguage,
	id,
	parentId,
	seasonApproval,
	seriesApproval,
	catalogue,
}) {
	if (
		(type !== "Episode" && type !== "Season" && type !== "Series")
		|| isSeasonList
	) {
		return null;
	}

	const linkUrl = getLinkUrl(id, parentId, type, catalogue, null, isTranslationList, translationLanguage);
	const linkTo = {
		pathname: linkUrl,
		state: {
			returnTo: isTranslationList ? window.location.pathname : undefined,
			returnToTitle: isTranslationList ? "Translations" : undefined,
		}
	};

	switch (type) {
		case "Episode":
		case "Season":
			let title = "";
			let label = "Display series";
			const seasonNeedsSeriesParent = type === "Season" && !parentId;
			if (seasonNeedsSeriesParent) {
				label = "Display season";
				title = " (needs to be connected to a series)";
			}
			else {
				const seriesIsMissingApproval = seriesApproval?.status?.toLowerCase() !== "approved";
				const seasonIsMissingApproval = seasonApproval?.status?.toLowerCase() !== "approved";
				const seriesTitle = seriesIsMissingApproval ? "series" : "";
				const seasonTitle = seasonIsMissingApproval ? "season" : "";
				title = seasonIsMissingApproval || seriesIsMissingApproval ? ` (${seriesTitle}${seriesIsMissingApproval && seasonIsMissingApproval ? " and " + seasonTitle : seasonTitle} needs approval)` : "";
			}

			return (
				<>
					<Link to={linkTo} className="c6-link">{label}</Link>
					<span className="fg-lightred">{title}</span>
				</>
			);
		case "Series":
			return <Link to={linkTo} className="c6-link">Display series</Link>;
	}
}

function getLinkUrl(id, parentId, type, catalogue = "all", hash, isTranslationList) {
	// const cataloguePath = !isTranslationList && catalogue ? `${catalogue}/` : "";
	let libraryOrTranslationPath = "programs";
	if (isTranslationList) {
		libraryOrTranslationPath = window.location.pathname.split("/").find(p => p.includes("translation"));
	}

	if (type === "Episode" || type === "Season") {
		const seasonId = type === "Season" ? id : parentId;
		const anchor = type === "Episode" ? `#p${hash || id}` : "";
		return `/metadata/${libraryOrTranslationPath}/${catalogue}/season/${seasonId}${anchor}`;
	}

	if (type === "Series") {
		return `/metadata/${libraryOrTranslationPath}/${catalogue}/series/${id}`;
	}
	
	console.warn("[Metadata] listItem.jsx-getLinkUrl: You should not end up here?")
	return "#listItem.jsx-getLinkUrl";
}

function getFamilyActions({ onFamilyActionClick, links = [], type, isTranslationList }) {
	if (
		appConfig.features && !appConfig.features.showProgramFamilies
		|| !onFamilyActionClick
		|| !["Series", "Single"].includes(type)
		|| isTranslationList
		|| !hasAccessToPath([{ module: "metadata", app: "families", access: "editor" }])
	) {
		return null;
	}

	// Families that will have a visible link from the start
	const visibleFamilies = links.slice(0, Math.min(3, links.length));
	const familyLinks = visibleFamilies.map(family => (
		<Link key={family.programId} className="c6-link family-action" to={`/metadata/families/${family.programId}`}>{family.name}</Link>
	));

	// Families that will be hidden behind a "2 more" link and shown when hovered
	const hiddenFamilies = links.slice(Math.min(3, links.length), links.length);
	if (hiddenFamilies.length) {
		familyLinks.push(
			<span key="wrapper" className="family-action unfold-on-hover" data-text={`${hiddenFamilies.length} more`}>
				{hiddenFamilies.map(family => (
					<Link key={family.programId} className="c6-link family-action" to={`/metadata/families/${family.programId}`}>{family.name}</Link>
				))}
			</span>
		);
	}
	
	return [
		familyLinks.length ? <span key="families">Families: </span> : null,
		...familyLinks,
		<Link key="add" className="c6-link family-action hidden" onClick={onFamilyActionClick}>Add to family</Link>,
	];
}

export function getMetadata({ type, defaultGenres, productionCountries, productionYear, duration, numberOfEpisodes, isSeasonList }) {
	const firstGenre = (type === "Series" || type === "Single") && defaultGenres && defaultGenres.length ? `${upperFirst(defaultGenres[0].name)}. ` : "";
	const firstCountry = productionCountries && productionCountries.length ? `${productionCountries[0].name}. ` : "";
	const year = productionYear ? `${productionYear}. ` : "";
	const programLength = duration ? `${duration} min. ` : "";
	const episodes = numberOfEpisodes && numberOfEpisodes > 0 ? `${numberOfEpisodes} episodes. ` : "";

	return ["Episode", "Template"].includes(type) && isSeasonList
		? programLength
		: firstGenre + firstCountry + year + programLength + episodes;
}

function getRightCountry(right) {
	return AllRegions.find(r => r.id === right.versionId).country.toUpperCase();
}

function renderRights(rights) {
	if (!rights.length) {
		return <div style={{ display: "inline-block", width: "50%" }}></div>;
	}

	let content = groupAndRenderRights(rights);

	return (
		<div style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
			{content}
		</div>
	);
}

function filterUnique(value, index, self) {
	return self.indexOf(value) === index;
}

function groupAndRenderRights(rightsGroup, groupKey = "") {

	let groupedRights = [];
	let groupedRightsMap = new Map();

	rightsGroup.forEach(right => {
		let key = {
			rightTypeClass: right.rightTypeClass,
			start: moment(right.regionStart ?? right.start).format(Const.DATE_FORMAT),
			end: moment(right.regionEnd ?? right.end).format(Const.DATE_FORMAT)
		};

		if (!groupedRightsMap.has(JSON.stringify(key))) {
			groupedRightsMap.set(JSON.stringify(key), []);
			groupedRights.push(key);
		}

		groupedRightsMap.get(JSON.stringify(key)).push(right);
	});

	return (
		<div className="program-right" key={groupKey}>
			{
				groupedRights.map((right, index) => {
					let includedRights = groupedRightsMap.get(JSON.stringify(right));
					let versions = includedRights.map(right => {
						return {
							...right,
							displayText: getRightCountry(right) + (right.source == 'Acq' ? '*' : '')
						}
					}).sort((a, b) => a.displayText - b.displayText);
					let sources = includedRights.map(right => right.source).filter(filterUnique).join(', ');
					const title = `${right.rightTypeClass}`;
					return (
						<div key={index} 
							title={(sources == 'Acq' ? " Not planned yet so only license dates available." : undefined)}
							className={(sources == 'Acq' ? " cursive" : "")}
						>
							<span>{title}</span>
							{
								versions && versions.map((version, vInd) => {
									return (
										<span key={vInd} title={`${version.owner}${version.source == 'Acq' ? ' - Not planned yet, showing license date' : ''}`}>{vInd?', ':''}{version.displayText}</span>
									);
								})
							}
							<Period start={right.regionStart ?? right.start} end={right.regionEnd ?? right.end} format={Const.DATE_FORMAT} renderSingleDateIfSame />
						</div>
					);
				})
			}
		</div>
	)
}

// TODO: somehow indicate if the visible right is active or upcoming?
function getRightPeriods({ rights }) {

	rights = (rights || [])
		.filter(right => right.versionId > 0) // Rights with versionId 0 is old trash
		.filter(right => !(["svod", "avod", "hvod"].includes(right.rightTypeClass?.toLowerCase()) && moment(right.start).isSame(right.end))) // Remove rights with the same start and end time WHEN WE HAVE A VOD RIGHT
		.sort((a, b) => {
			let titleA = appConfig.features.metadataUseRightTypeDisplayName ? a.rightTypeDisplayName ?? a.rightTypeClass : a.rightTypeClass;
			let titleB = appConfig.features.metadataUseRightTypeDisplayName ? b.rightTypeDisplayName ?? b.rightTypeClass : b.rightTypeClass;
			return titleA.localeCompare(titleB, undefined, { numeric: true });
		});

	const linearRights = rights.filter(right => ["linear"].includes(right.rightTypeClass?.toLowerCase()));
	const vodRights = rights.filter(right => ["svod", "avod", "hvod"].includes(right.rightTypeClass?.toLowerCase()));

	// Uncomment length check below to render c6-content only for items with rights (this can cause weird alignment)
	return /*(linearRights.length > 0 || svodRights.length > 0) &&*/ (
		<Content className="rights">
			{renderRights(linearRights)}
			{renderRights(vodRights)}
		</Content>
	);
}

export function getAction({
	id,
	title = "Missing type",
	approval,
	onClick,
	versionId,
	reference,
	guid,
	provider,
	hasAccessToPath = () => true,
	access,
	disabled,
	type,
	name,
	catalogue,
	ignore,
	newRow = false,
	className = "",
	isTranslationList,
}) {
	const approved = approval && approval.status && approval.status.toLowerCase() === "approved";
	let approvedClass = approved
		? "fg-green icon-check"
		: "fg-red icon-close";
	if (ignore) {
		approvedClass = "c6-color-light icon-close";
	}

	// HACK: We should find a way to validate router navigation routes when rendering them
	// The problem with using the react-router match util is that it's async so we need to
	// store the state and update it when the match resolves, which is not ideal
	// const route = [{ module: "metadata", app: "images", access: "reader" }];

	const metadataContainerType = type ? MetadataContainerTypes.find(item => item.name === type) : null;
	const typeId = metadataContainerType ? metadataContainerType.id : null;
	const typeName = metadataContainerType?.starContainerTypeName;
	if (type && type !== "Template" && typeId == null) {
		console.warn("Could not find match for type %s in MetadataContainerTypes. This could cause problems when opening editors!", type);
	}

	const hasAccess = hasAccessToPath([access]);
	const { pathname, query } = getPath(title, id, versionId, provider, reference, guid, name, catalogue);

	let wideModal = undefined;
	if (isTranslationList && versionId) {
		wideModal = true;
	}

	return (
		<button
			key={title}
			disabled={!hasAccess || disabled || ignore}
			className={`action ${approvedClass} ${newRow ? "new-row" : ""} ${className}`}
			onClick={hasAccess ? onClick?.bind(this, id, pathname, wideModal, typeId, query, typeName) : null}
		>
			<span>{title}</span>
		</button>
	);
}

function getPath(title, id, versionId, provider, reference, guid, name, catalogue) {
	const locale = versionId ? `/locale/${versionId}` : '';
	const cataloguePath = catalogue ? `/${catalogue}` : "";

	if (title === "Media") {
		return { pathname: `/metadata/images/${ENTITY}/${id}${cataloguePath}` };
	} else if (title === "Tags" || title === AGE_RATING_TITLE) {
		const type = title === AGE_RATING_TITLE ? "agerating" : title.toLowerCase().replace(/\s/g, "");
		return {
			pathname: `/metadata/${ENTITY}s/${id}/${type}/edit${cataloguePath}`,
		};
	}

	return { pathname: `/metadata/${ENTITY}s/${id}${locale}/edit${cataloguePath}` };
}

// TODO!!!: We should possibly just render the versions which the API provides and remove this
// since programs for the same customer need to have different language editors. (C More also
// has TV4 programs which only are available on the Swedish C More service)
function getCatalogueVersions(catalogue, translationLanguage) {
	if (translationLanguage) {
		const sourceVersionId = AllRegions.find(r => r.languageDisplayName.toLowerCase() === appConfig.features.metadataTranslationSourceLang.toLowerCase()).id;
		const targetVersionId = AllRegions.find(r => r.languageDisplayName.toLowerCase() === translationLanguage.toLowerCase()).id;
		return [sourceVersionId, targetVersionId];
	}

	const localeEditorVersions = MetadataModule.catalogueMap[catalogue]?.uiData?.localeEditorVersions ?? [];
	
	return localeEditorVersions.map(v => v.targetVersionId);
}

function getTargetStore(isSeasonList, isTranslationList) {
	if (isSeasonList) {
		return "seriesSeasonPrograms";
	}
	if (isTranslationList) {
		return "translations";
	}

	return null;
}

function getDataSourceInfo(dataSource) {
	if (dataSource === "onestopdrop") {
		return (
			<span
				title="A content provider has provided metadata for this program."
				className="icon-file-check"
				style={{ color: "var(--ok-color)" }}
			></span>
		);
	}
}

function getProvider({ type, provider }) {
	if ((type === "Season" || type === "Single") && provider && provider.name) {
		return <span style={{ color: "var(--text-light-color)" }}>({provider.name})</span>;
	}

	return null;
}

function shouldIgnoreVersion(program, versionId) {
	if (appConfig.features.metadataIgnoreVersionsWithoutRightsExceptions?.split(",").map(v => parseInt(v)).includes(versionId)) {
		return false;
	}

	return !programHasRightsForVersion(program, versionId);
}

function programHasRightsForVersion(program, versionId) {	
	return program.rights?.some(r => r.versionId === versionId);
}
