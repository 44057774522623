export const EVENTPLANNER_DATE_FILTER = "__EVENTPLANNER_DATE_FILTER__";

export function getSeriesOptions(series, defaultTextName) {
	const seriesWithActiveEvents = (series ?? []).filter(s => s.hasActiveEvents);
	const seriesWithoutActiveEvents = (series ?? []).filter(s => !s.hasActiveEvents);
	const options = [
		{ key: "", text: defaultTextName },
		{ key: "_single", text: "Single events" }, // Must be handled by transformFilter
	];

	// Active
	options.push(...getSeriesOptionsGroupedByGenre(seriesWithActiveEvents, "active"));

	// Separator between active and inactive series
	if (seriesWithActiveEvents.length && seriesWithoutActiveEvents.length) {
		options.push({
			key: "active-inactive-separator",
			text: "-- Series without any current events --",
			description: "",
			inset: false,
			disabled: true,
		});
	}

	// Inactive
	options.push(...getSeriesOptionsGroupedByGenre(seriesWithoutActiveEvents, "inactive"));

	return options;
}

function getSeriesOptionsGroupedByGenre(series, keyPrefix) {
	let options = [];
	// Add series without a genre
	const seriesWithoutGenre = series.filter(s => !s.genre)
		.map(s => ({
			reactKey: `${keyPrefix}-${s.id}`,
			key: s.id,
			text: s.name,
			description: "",
			inset: false,
		}));
	options.push(...seriesWithoutGenre);

	// Group other series by genre
	// Fotboll
	//  -> Allsvenskan
	//  -> Superettan
	// Handboll
	//  -> Handbollsligan
	//  -> SHE
	const genres = series.map(s => s.genre)
		.filter(g => !!g)
		.filter((g, i, arr) => arr.findIndex(g2 => g2.id === g.id) === i)
		.sort((g1, g2) => g1.name.localeCompare(g2.name));
	genres.forEach(g => {
		// Add the genre (Fotboll, Handboll...)
		options.push({
			reactKey: `${keyPrefix}-genre-${g.id}`,
			key: `_genre:${g.id}`, // Must be handled by transformFilter
			text: g.name,
			description: "",
			inset: false,
			strong: true,
		});

		// Add leagues in that genre (Allsvenskan, Superettan...)
		const genreSeries = series.filter(s => s.genre?.id === g.id)
			.sort((s1, s2) => s1.name.localeCompare(s2.name));
		genreSeries.forEach(s => {
			options.push({
				reactKey: `${keyPrefix}-${s.id}`,
				key: s.id,
				text: s.name,
				description: "",
				inset: true,
			});
		});
	});

	return options;
}

export function getSettingsOptions(provider) {
	let settingsOttOption = { key: "ottTv4", text: "TV4" };
	if (provider === "mtv") {
		settingsOttOption = { key: "ottMtv", text: "MTV" };
	}
	
	return [
		settingsOttOption,
		{ key: "_ottOnly", text: "OTT only" },
		{ key: "_hvod", text: "HVOD events" },
	];
}

export function transformFilters(filters, provider) {
	const transformed = { ...filters };
	
	const seriesId = filters["season.series.id"];
	if (seriesId?.startsWith("_genre:")) {
		const [, genreId] = seriesId.split(":");
		transformed["season.series.genre.id"] = genreId;
		delete transformed["season.series.id"];
	}
	
	// The "Show" filter is only used by the frontend
	delete transformed["show"];

	if (seriesId === "_single") {
		transformed["season"] = "null";
		delete transformed["season.series.id"];
	}

	if (filters["settings-and"]?.includes("_hvod")) {
		transformed["settings-and"] = transformed["settings-and"].split(",").filter(s => s !== "_hvod").join(",");
		transformed[provider === "mtv" ? "ottmtvrights.rights.type" : "otttv4rights.rights.type"] = "HVOD";
	}
	if (filters["settings-and"]?.includes("_ottOnly")) {
		transformed["settings-and"] = transformed["settings-and"].split(",").filter(s => s !== "_ottOnly").join(",");
		transformed["!=hasEventBroadcasts"] = true;
	}

	return transformed;
}